body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.button-margin {
  margin-top: 21px;
}

.col-margin {
  margin-bottom: 15px;
}
.form-control {
  appearance: auto !important;
}

.no-padding-left {
  padding-left: 0 !important;
}

.termination-reason-container {
  padding-left: 12px;
}